import { PLAN_DETAIL } from "../actions/plan";

const initialState = {
  planDetail: null,
};

const plan = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_DETAIL:
      return {
        ...state,
        planDetail: action.payload,
      };
      break;
    default:
  }
  return state;
};
export default plan;
