import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import { editPlan, GetPlansWithId, PLAN_DETAIL } from "../../actions/plan";
import { getCurrency } from "../../actions/auth";
import ReactSelectField from "../../components/FormFields/ReactSelectField";

function EditPlan() {
  // local varibales
  const [currency, setCurrency] = useState([]);
  // use hooks
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      planPrice: [
        {
          currencyId: "",
          amount: "",
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "planPrice", // unique name for your Field Array
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // on form submissions
  const onSubmit = async (formData) => {
    console.log(formData, "formData");
    try {
      await editPlan({ ...formData, id: id });
      navigate(-1);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const onAddAmount = () => {
    append({ currencyId: "", amount: "" });
  };

  const onDeleteAmount = (index) => {
    remove(index);
  };
  const getPlanById = async () => {
    try {
      let resp = await GetPlansWithId({ id });
      let records = resp?.data?.responseData[0];

      reset(records);
      dispatch({ type: PLAN_DETAIL, payload: records });
      
      // setValue("name", records?.name);
      // setValue("duration", records?.duration);
      // setValue("description", records?.description);
      // setValue("planPrice", records?.planPrice);

    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  const getAllCurrency = async () => {
    try {
      const resp = await getCurrency();
      setCurrency(resp?.data?.responseData?.currency);
      getPlanById(id);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  // usehooks
  useEffect(() => {
    if (id) {
      getAllCurrency();
    }
  }, [id]);

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">
          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Edit Plan</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Basic Details</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              label="Name*"
                              name="name"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Name is required",
                                },
                                maxLength: {
                                  value: 40,
                                  message: "Maximum 40 characters are allowed!",
                                },
                              }}
                            />
                          </div>

                          <div className="col-lg-6">
                            <Inputfield
                              control={control}
                              label="Duration*"
                              name="duration"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Duration is required",
                                },
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <InputTextAreaField
                              control={control}
                              name="description"
                              label={"Description"}
                              placeholder="Description"
                              type="text"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Description is required",
                                },
                              }}
                            />
                          </div>
                        </div>

                        {fields.length > 0 && fields?.map((_field, index) => (
                          <div className="row" key={index}>
                            <div className="col-lg-5">
                              <ReactSelectField
                                control={control}
                                name={`planPrice[${index}].currencyId`}
                                label={"Select Currency"}
                                placeholder="Select Currency"
                                options={currency}
                                optionValue="id"
                                onSelect={(e) => setValue("", e?.id)}
                                optionLabel="currencyCode"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Currency is required",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-lg-5">
                              <Inputfield
                                control={control}
                                name={`planPrice[${index}].amount`}
                                label="Price*"
                                normalize={(e) => e.replace(/^\s+/g, "")}
                                type="number"
                                fieldClass="form-control"
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Price is required",
                                  },
                                }}
                              />
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                className="icon-ic_plus"
                                onClick={() => onAddAmount()}
                              >
                                +
                              </button>
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="icon-ic_plus"
                                  onClick={() => onDeleteAmount(index)}
                                >
                                  -
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Submit details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditPlan;
