import axios from "axios";
export const PLAN_DETAIL = "PLAN_DETAIL";

export function GetPlans(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'plan'
    })
}

export function GetPlansWithId(payload){
    return axios({
        method: 'GET',
        params: payload,
        url: 'plan/id'
    })
}

export function PlanAdd(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'plan'
    })
}

export function planDelete(payload){
    return axios({
        method: 'DELETE',
        params: payload,
        url: 'plan'
    })
}

export function editPlan(payload){
    return axios({
        method: 'POST',
        data: payload,
        url: 'plan'
    })
}

export function planStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: 'plan-status'
    })
}
